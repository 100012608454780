import React, {
  useRef, useState, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle
} from 'react';
import {
  string, bool, shape, oneOfType, arrayOf, func, elementType
} from 'prop-types';
import { Hooks, Popover } from '@jotforminc/uikit';
import { tagDuplicateQuestions, isBright } from '@jotforminc/utils';
import { useFuse } from '@jotforminc/hooks';
import FormFields from '@jotforminc/form-fields-menu';
import UserList from '@jotforminc/user-list';
import { STATIC_FIELDS_WITH_PLACEHOLDER } from '@jotforminc/constants';

import difference from 'lodash/difference';
import Tagify from '@yaireo/tagify/dist/react.tagify';
import uniqBy from 'lodash/uniqBy';
import '@yaireo/tagify/dist/tagify.css';

import './styles/TagInput.scss';
import {
  getTagifyValue, getSelectedPlaceholderList,
  tagifyQuestion, tagifyPlaceholderList,
  getStaticFieldsAsSimpleQuestions, getSanitizedValue,
  sanitizeTextBeforeSet
} from './utils';
import patchTagify from './patch';
import {
  STATIC_FIELDS_WITH_PLACEHOLDER_FOR_SACL,
  EMAIL_TEMPLATE_TYPES
} from './constants';

const FIELD_REGEX = /\{[^}]+\}/gi;

const getAvailableUsers = (userList, addedTags) => {
  if (!addedTags) {
    return userList;
  }
  return userList.filter(a => !addedTags.find(tag => tag.value === a.email));
};

// eslint-disable-next-line max-statements
const TagInput = forwardRef(({
  value, defaultValue, addFormFields, addStaticFields, questions: questionsProp, allowDuplicateFields, formTitle, onRemove,
  settings, onChange, userList, popoverProps, SuggestionRenderer, onBlur, showFormFieldsOnClick, staticFieldsList, onFocus, isEncrypted,
  isSingleEntry, fieldsLabelText, useMultipleForms, formArray, showProtectedTag, showFormFieldsButtonOnFocus, validateSelectedBeforeAdd, keepInvalidTags, onAddTag,
  duplicatedEmails, useTextToTag, ...tagifyProps
}, ref) => {
  const { mode, emailType } = settings;
  const isModeMix = mode === 'mix';
  const isEmailTypeSACL = emailType === EMAIL_TEMPLATE_TYPES.FORM_SAVE_AND_CONTINUE_LATER;
  const questions = useMemo(() => (useMultipleForms ? formArray.flatMap(({ questions: q }) => q) : questionsProp), [formArray, questionsProp, useMultipleForms]);
  const questionList = useMemo(() => tagDuplicateQuestions(Array.isArray(questions) ? questions : Object.values(questions || [])), [questions]);

  const staticFieldsAsSimpleQuestions = useMemo(() => getStaticFieldsAsSimpleQuestions(useMultipleForms ? formArray.flatMap(f => {
    const newPlaceholder = p => (f.isMain ? p.placeholder : p.placeholder.replace('{', `{f${f.id}_`));
    return staticFieldsList.map(s => ({ ...s, placeholder: newPlaceholder(s) }));
  }) : staticFieldsList), [staticFieldsList, formArray]);
  const staticFieldQuestionList = useMemo(() => (addStaticFields ? staticFieldsList : []), [addStaticFields]);
  const questionsWithStaticFieldsList = useMemo(() => [...questionList, ...staticFieldsAsSimpleQuestions], [questionList, staticFieldsAsSimpleQuestions]);
  const whiteList = useRef([]);

  useEffect(() => {
    whiteList.current = [
      ...whiteList.current,
      ...questionsWithStaticFieldsList.map(question => tagifyQuestion({ question, asString: false, mode })).filter(q => q)
    ];
    whiteList.current = uniqBy(whiteList.current, 'name');
  }, [questionsWithStaticFieldsList]);

  const sanitizedValue = useMemo(() => sanitizeTextBeforeSet(value || defaultValue), [value, defaultValue]);
  const tagifyRef = useRef(false);
  const suggestionListRef = useRef(false);
  const formFieldsRef = useRef(false);
  const tagifyContRef = useRef(false);
  const inputRef = { current: tagifyRef.current?.DOM?.input };
  const tagInputRef = useRef(false);
  const rootRef = Hooks.useCombinedRefs(tagInputRef, ref);
  const [textInputValue, setTextInputValue] = useState('');
  const [isFocused, setFocus] = useState(false);
  const [isFormFieldsVisible, setFormFieldsVisible] = useState(false);
  const [isSuggestionVisible, setSuggestionVisibility] = Hooks.useClickOutsideState(false, [suggestionListRef, inputRef]);
  const [tagifyValue, setTagifyValue] = useState(getTagifyValue(sanitizedValue, questionsWithStaticFieldsList, mode));
  const selectedPlaceholderList = getSelectedPlaceholderList(tagifyValue, questionsWithStaticFieldsList, mode);
  const userListSource = useMemo(() => getAvailableUsers(userList, tagifyRef.current.value), [tagifyValue]);
  const suggestedUserList = useFuse(userListSource, textInputValue);

  const handleSelectionChange = placeholderList => {
    if (!validateSelectedBeforeAdd(placeholderList)) return;

    if (isSingleEntry) {
      tagifyRef.current.removeAllTags();
    } else {
      const removedPlaceholderList = !allowDuplicateFields ? difference(selectedPlaceholderList, placeholderList) : [];
      tagifyRef.current.removeTags(tagifyPlaceholderList(removedPlaceholderList, questionsWithStaticFieldsList, mode).map(({ value: tmpValue }) => tmpValue));
    }
    onAddTag(placeholderList);
    const addedPlaceholderList = !allowDuplicateFields ? difference(placeholderList, selectedPlaceholderList) : placeholderList;
    const tags = tagifyPlaceholderList(addedPlaceholderList, questionsWithStaticFieldsList, mode);
    if (!tagifyRef.current.state?.selection?.range || tags.length > 1) {
      tagifyRef.current.addTags(tags);
    } else if (tags.length === 1) {
      const tagElem = tagifyRef.current.createTagElem(tags[0]);
      tagifyRef.current.injectAtCaret(tagElem);
      if (tagElem.previousSibling?.wholeText !== ' ') tagElem.before(document.createTextNode(' '));
      tagifyRef.current.placeCaretAfterNode(tagifyRef.current.insertAfterTag(tagElem));
    }
  };

  const splitTagsAndTexts = (addedTags = [], node) => {
    const invalidsShouldRemoved = !validateSelectedBeforeAdd(addedTags);
    const duplicatsShouldRemoved = !allowDuplicateFields && selectedPlaceholderList.some(item => addedTags.includes(item));

    if (invalidsShouldRemoved || duplicatsShouldRemoved) {
      node.textContent = node.textContent.replace(FIELD_REGEX, ''); // eslint-disable-line no-param-reassign
      tagifyRef.current.placeCaretAfterNode(node);
      tagifyRef.current.update();
      return;
    }

    const tags = tagifyPlaceholderList(addedTags, questionsWithStaticFieldsList, mode);
    if (isSingleEntry) {
      tagifyRef.current.removeAllTags();
      tagifyRef.current.addTags(tags);
    } else {
      const { textContent } = node;
      const [firstPartOfText, lastPartOfText] = textContent.split(FIELD_REGEX);
      node.textContent = `${firstPartOfText} `; // eslint-disable-line no-param-reassign
      const tagElem = tagifyRef.current.createTagElem(tags[0]);
      node.after(tagElem);
      tagElem.after(document.createTextNode(` ${lastPartOfText}`));
      tagifyRef.current.placeCaretAfterNode(tagElem);
      tagifyRef.current.update();
    }
    onAddTag([...selectedPlaceholderList, ...addedTags]);
  };

  const textToTag = () => {
    inputRef.current.childNodes.forEach(node => {
      const matchedTags = node.textContent.match(FIELD_REGEX) || [];
      if (node.nodeType === global.Node.TEXT_NODE && matchedTags.length > 0) {
        splitTagsAndTexts(matchedTags, node);
      }
    });
  };

  const handleTagifyChange = ({ target: { value: tmpValue } }) => {
    const sanitizedText = sanitizeTextBeforeSet(tmpValue);
    setTagifyValue(sanitizedText);
    if (useTextToTag) textToTag();
  };
  const handleInputChange = e => {
    const { detail: { textContent, value: tmpValue } } = e;
    const nextValue = (isModeMix ? textContent.split(/\n/).pop().trim() : tmpValue);
    if (isSingleEntry) {
      tagifyRef.current.removeTags();
    }

    tagifyRef.current.update();
    setTextInputValue(nextValue);
  };

  const handleSuggestionClick = ({ email }) => {
    setTextInputValue('');

    if (isSingleEntry) {
      tagifyRef.current.removeAllTags();

      if (inputRef.current) {
        inputRef.current.innerHTML = email;
      }

      tagifyRef.current.update();
      setSuggestionVisibility(false);
      return;
    }

    tagifyRef.current.addTags([email]);
    return !isModeMix
      ? tagifyRef.current.input.set.call(tagifyRef.current)
      : inputRef.current.childNodes.forEach(node => {
        if (node.nodeType === global.Node.TEXT_NODE && node.textContent === textInputValue) {
          node.remove();
        }
      });
  };

  const addTags = (tagValue, removeAllTags) => {
    const newTagifyValue = getTagifyValue(tagValue, questionsWithStaticFieldsList, mode);
    const newSelectedPlaceholderList = getSelectedPlaceholderList(newTagifyValue, questionsWithStaticFieldsList, mode);
    if (removeAllTags || isSingleEntry) {
      tagifyRef.current.removeAllTags();
    }
    tagifyRef.current.addTags(tagifyPlaceholderList(newSelectedPlaceholderList, questionsWithStaticFieldsList, mode));
  };

  const onInputClick = () => {
    if (showFormFieldsOnClick) {
      formFieldsRef.current.setMenuVisibility(true);
    }
  };

  useEffect(() => {
    if (tagifyRef.current) {
      tagifyRef.current = patchTagify(tagifyRef.current);
      tagifyRef.current?.DOM?.input?.setAttribute('aria-label', tagifyProps.ariaLabel);
    }
  }, [tagifyRef.current]);

  useEffect(() => {
    setSuggestionVisibility(Boolean(textInputValue && suggestedUserList.length > 0));
  }, [textInputValue, suggestedUserList]);
  Hooks.useEffectIgnoreFirst(() => {
    onChange(getSanitizedValue(isModeMix, tagifyValue, selectedPlaceholderList, questionsWithStaticFieldsList));
  }, [tagifyValue]);

  const clearWhiteSpaces = () => {
    const textNodes = [...tagifyRef.current.DOM.input.childNodes].filter(node => node.nodeType === global.Node.TEXT_NODE);
    textNodes.forEach((node, i) => {
      const isPrevTag = node.previousSibling?.nodeName === 'TAG';
      const isNextTag = node.nextSibling?.nodeName === 'TAG';

      let text = node.nodeValue;
      if (i === 0 && !isPrevTag) {
        text = text.trimStart();
      }
      if (i === textNodes.length - 1 && !isNextTag) {
        text = text.trimEnd();
      }
      text = text.split(/\s+/).join(' ');
      node.nodeValue = text; // eslint-disable-line no-param-reassign
    });
    tagifyRef.current.update();
  };

  Hooks.useEffectIgnoreFirst(() => {
    if (!isFormFieldsVisible) clearWhiteSpaces();
  }, [isFormFieldsVisible]);

  const handleBlur = (event, ...params) => {
    const inputValue = event.detail.tagify.DOM.originalInput.value;
    const sanitized = getSanitizedValue(isModeMix, inputValue, selectedPlaceholderList, questionsWithStaticFieldsList);
    // eslint-disable-next-line no-param-reassign
    event.target.value = sanitized;
    onBlur(event, ...params);
    setFocus(false);
    if (!event.detail.relatedTarget || event.detail.relatedTarget.getAttribute('class') !== 'formFields-button') {
      clearWhiteSpaces();
    }
  };

  const handleFocus = (event, ...params) => {
    onFocus(event, ...params);
    setFocus(true);
  };

  const handleRemove = (event, ...params) => {
    const inputValue = event.detail.tagify.DOM.originalInput.value;
    const sanitized = getSanitizedValue(isModeMix, inputValue, selectedPlaceholderList, keepInvalidTags, questionsWithStaticFieldsList);
    // eslint-disable-next-line no-param-reassign
    event.target.value = sanitized;
    onRemove(event, ...params);
  };

  const colorifyTags = useCallback(() => {
    tagifyRef.current.getTagElms().forEach(elm => {
      const name = /{f(\d*)_/gm.exec(elm.__tagifyTagData.name)?.[1];
      const { color } = formArray.find(({ id }) => id.toString() === name?.toString?.()) || formArray[0];
      const isDark = isBright(color);
      elm.style.setProperty('--tag-bg', color);
      elm.style.setProperty('--tag-text-color', isDark ? '#000' : '#fff');
      elm.setAttribute('data-state', isDark ? 'dark' : 'light');
    });
  }, [formArray]);

  useEffect(() => {
    if (useMultipleForms && formArray.length && formArray[0].color && tagifyRef.current?.getTagElms?.()?.length) {
      colorifyTags();
    }
  }, [formArray, value, colorifyTags, tagifyValue, useMultipleForms]);

  useImperativeHandle(rootRef, () => ({
    tagifyRef,
    addTags,
    formFieldsRef
  }));

  const isFormFieldsButtonVisible = showFormFieldsButtonOnFocus || !showFormFieldsOnClick;
  const formFieldsMenuTargetRef = showFormFieldsButtonOnFocus || showFormFieldsOnClick ? tagifyContRef : null;

  return (
    <div
      className={`jfTagInput${isFocused ? ' isFocused' : ''}${isFormFieldsVisible ? ' isFormFieldsVisible' : ''}`}
      onClick={onInputClick}
      ref={tagifyContRef}
    >
      <Tagify
        settings={{
          ...(addFormFields && questionList.length > 0 ? { whitelist: whiteList.current, prefix: /{/ } : {}),
          keepInvalidTags: keepInvalidTags,
          addTagOnBlur: false,
          delimiters: ',| ',
          duplicates: allowDuplicateFields,
          mixTagsInterpolator: ['#start#', '#end#'],
          ...settings,
          hooks: {
            beforeRemoveTag: () => {
              tagifyRef.current.update();
              setTextInputValue('');
              return new Promise(resolve => {
                resolve(true);
              });
            }
          },
          callbacks: {
            add: () => setTextInputValue(''),
            remove: () => {
              tagifyRef.current.update();
            }
          }
        }}
        {...tagifyProps}
        tagifyRef={tagifyRef}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onInput={handleInputChange}
        onChange={handleTagifyChange}
        onRemove={handleRemove}
      >
        {isModeMix ? tagifyValue : `[${tagifyValue}]`}
      </Tagify>
      {addFormFields && questions && (
        <FormFields
          ref={formFieldsRef}
          isButtonVisible={isFormFieldsButtonVisible}
          formTitle={formTitle}
          menuTargetRef={formFieldsMenuTargetRef}
          isSingle={!allowDuplicateFields}
          addStaticFields={addStaticFields}
          staticFields={!isEmailTypeSACL ? staticFieldQuestionList : STATIC_FIELDS_WITH_PLACEHOLDER_FOR_SACL}
          questions={!isEmailTypeSACL ? questionList : []}
          onChange={handleSelectionChange}
          defaultValue={selectedPlaceholderList}
          fieldsLabelText={fieldsLabelText}
          useMultipleForms={useMultipleForms}
          formArray={formArray}
          showProtectedTag={showProtectedTag}
          onVisibilityChange={setFormFieldsVisible}
          duplicatedEmails={duplicatedEmails}
          isEncrypted={isEncrypted}
        />
      )}
      {isSuggestionVisible && (
        <Popover
          ref={suggestionListRef}
          targetRef={tagifyContRef}
          usePortal={true}
          {...popoverProps}
          popoverOptions={{
            placement: 'bottom-start'
          }}
          style={{ zIndex: 7050 }}
        >
          <SuggestionRenderer
            list={suggestedUserList}
            onClick={handleSuggestionClick}
            useKeyboardEvents
          />
        </Popover>
      )}
    </div>
  );
});

TagInput.propTypes = {
  value: string,
  defaultValue: string,
  addFormFields: bool,
  addStaticFields: bool,
  staticFieldsList: arrayOf(shape({})),
  questions: oneOfType([
    shape({}),
    arrayOf(shape({}))
  ]),
  settings: shape({}),
  userList: arrayOf(shape({})),
  popoverProps: shape({}),
  SuggestionRenderer: elementType,
  allowDuplicateFields: bool,
  onChange: func,
  onBlur: func,
  onFocus: func,
  onRemove: func,
  formTitle: string,
  showFormFieldsOnClick: bool,
  isSingleEntry: bool,
  fieldsLabelText: string,
  useMultipleForms: bool,
  formArray: arrayOf(shape({})),
  showProtectedTag: bool,
  showFormFieldsButtonOnFocus: bool,
  validateSelectedBeforeAdd: func,
  keepInvalidTags: bool,
  duplicatedEmails: arrayOf(shape({})),
  isEncrypted: bool,
  onAddTag: func,
  useTextToTag: bool
};

TagInput.defaultProps = {
  value: '',
  defaultValue: '',
  addFormFields: false,
  addStaticFields: false,
  staticFieldsList: STATIC_FIELDS_WITH_PLACEHOLDER,
  questions: undefined,
  settings: {},
  popoverProps: {},
  userList: [],
  SuggestionRenderer: UserList,
  allowDuplicateFields: true,
  onChange: f => f,
  onBlur: f => f,
  onFocus: f => f,
  onRemove: f => f,
  formTitle: undefined,
  showFormFieldsOnClick: false,
  isSingleEntry: false,
  fieldsLabelText: 'Form Fields',
  useMultipleForms: false,
  formArray: [],
  showProtectedTag: false,
  showFormFieldsButtonOnFocus: false,
  validateSelectedBeforeAdd: () => true,
  keepInvalidTags: true,
  duplicatedEmails: [],
  isEncrypted: false,
  onAddTag: f => f,
  useTextToTag: false
};

export default TagInput;
